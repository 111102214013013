import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import ReactMarkdown from "react-markdown"
import { useMediaPredicate } from "react-media-hook"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { Card } from "../../components/card/card"
import { CoverMain } from "../../components/cover-main/cover-main"
import { CardSlider } from "../../components/card-slider/card-slider"
import { HeadingBasic } from "../../components/heading/heading"
import { Container, Section } from "../../components/grid/grid"
import { Button } from "../../components/buttons/buttons"
import { LlvGoogleMapsEmbed } from "../../components/google-map/google-map"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const LivingLabVenturesPage = ({ location }) => {

  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        page(id: "living-lab-ventures", idType: URI) {
          title
          featuredImage {
            mediaItemUrl
          }
          embedLlvLocation {
            embedUrl
          }
          blocks {
            name
            ... on WordPress_CoreCoverBlock {
              attributes {
                url
                className
              }
            }
            ... on WordPress_CoreGroupBlock {
              name
              attributes {
                className
              }
              innerBlocks {
                ... on WordPress_CoreHeadingBlock {
                  name
                  attributes {
                    content
                    level
                  }
                }
                ... on WordPress_CoreParagraphBlock {
                  name
                  attributes {
                    ... on WordPress_CoreParagraphBlockAttributesV3 {
                      align
                      backgroundColor
                      content
                      className
                    }
                  }
                  originalContent
                }
                ... on WordPress_CoreImageBlock {
                  name
                  attributes {
                    url
                    title
                    className
                    href
                  }
                }
                ... on WordPress_AcfAcfgalleryBlock {
                  name
                  acf {
                    carousel_type
                    images {
                      title
                      slug
                      sourceUrl
                      caption(format: RAW)
                      description
                    }
                  }
                }
                ... on WordPress_CoreListBlock {
                  name
                  attributes {
                    values
                  }
                }
                ... on WordPress_CoreShortcodeBlock {
                  name
                  attributes {
                    text
                  }
                }
                ... on WordPress_AcfListImageBlock {
                  name
                  attributes {
                    className
                  }
                  acf {
                    title
                    text
                    list {
                      img {
                        sourceUrl
                      }
                      title
                      text
                    }
                  }
                }
                ... on WordPress_AcfLabelvaluesBlock {
                  name
                  acf {
                    list_label_values {
                      label
                      value
                    }
                  }
                }
                ... on WordPress_CoreGroupBlock {
                  name
                  attributes {
                    className
                  }
                  innerBlocks {
                    ... on WordPress_CoreHeadingBlock {
                      name
                      attributes {
                        content
                        level
                      }
                    }
                    ... on WordPress_CoreParagraphBlock {
                      name
                      attributes {
                        ... on WordPress_CoreParagraphBlockAttributesV3 {
                          align
                          backgroundColor
                          content
                          className
                        }
                      }
                      originalContent
                    }
                    ... on WordPress_CoreImageBlock {
                      name
                      attributes {
                        url
                        title
                        className
                      }
                    }
                    ... on WordPress_AcfAcfgalleryBlock {
                      name
                      acf {
                        carousel_type
                        images {
                          title
                          slug
                          sourceUrl
                          caption(format: RAW)
                          description(format: RAW)
                        }
                      }
                    }
                    ... on WordPress_CoreListBlock {
                      name
                      attributes {
                        values
                      }
                    }
                    ... on WordPress_CoreShortcodeBlock {
                      name
                      attributes {
                        text
                      }
                    }
                    ... on WordPress_AcfListImageBlock {
                      name
                      attributes {
                        className
                      }
                      acf {
                        title
                        text
                        list {
                          img {
                            sourceUrl
                          }
                          title
                          text
                        }
                      }
                    }
                    ... on WordPress_AcfLabelvaluesBlock {
                      name
                      acf {
                        list_label_values {
                          label
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
            focuskw
          }
        }
      }
    }
  `)

  let coverItems = []
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const ogUrl = process.env.BASE_URL + location.pathname + "/"
  const wordPress = staticData?.wordPress?.page
  const seo = wordPress?.seo
  const featuredImage = wordPress?.featuredImage?.mediaItemUrl
  let embedMaps = wordPress?.embedLlvLocation?.embedUrl

  const buttonScrollHandler = (target) => {
    var scrollContainer = target
    do { //find scroll container
      scrollContainer = scrollContainer.parentNode
      if (!scrollContainer) return
      scrollContainer.scrollTop += 1
    } while (scrollContainer.scrollTop == 0)
  
    var targetY = -60
    do { //find the top of target relatively to the container
      if (target == scrollContainer) break
      targetY += target.offsetTop
    } while (target = target.offsetParent)
  
    const scroll = function(c, a, b, i) {
      i++; if (i > 30) return
      c.scrollTop = a + (b - a) / 30 * i
      setTimeout(function(){ scroll(c, a, b, i); }, 20)
    }
    // start scrolling
    scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0)
  }

  // eslint-disable-next-line
  wordPress && wordPress?.blocks?.map(item => {
    if(item.name === "core/cover"){
      coverItems.push({
        url: item?.attributes?.url, 
        className: item?.attributes?.className
      })
    }
  })

  useEffect(() => {
    // eslint-disable-next-line
    wordPress && wordPress?.blocks?.map(item => {
      if(item.name === "core/cover"){
        coverItems.push({
          url: item?.attributes?.url, 
          className: item?.attributes?.className
        })
      }
    })
  }, [])

  return(
    <Layout location={location} currentLocation={location.pathname} lang="ID">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        url={ogUrl}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        twitterImg={featuredImage}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        img={featuredImage}
        fbImg={featuredImage}
      />
      <Helmet>
        <body className="bd-page" />
        <meta 
          name="keywords"
          content={seo?.focuskw ? seo?.focuskw : ""}
        ></meta>
      </Helmet>
      <CoverMain
        h1={""}
        img={resizeScreen ? coverItems[1].url : coverItems[0]?.url}
        alt={wordPress?.title}
        className={`cover-md ${resizeScreen ? coverItems[1]?.className : coverItems[0]?.className}`}
      />
      <Section>
        <Container className={`llv-wrapper`}>
          <div className="col-md-12 llv-row p-0">
            {wordPress?.blocks?.map((item, i) => {
              switch (item?.name) {
                case "core/group":
                  if (item?.attributes?.className === "base-program-group") {
                    return(
                      <>
                        <div className="row col-md-12 head-llv m-0">
                          <div className="row col-md-12 p-0 tab-section">
                            <div className="nav-tabs-wrapper d-flex">
                              <ul className="nav nav-tabs pt-4" role="tablist">
                                <li className="nav-item">
                                  <button 
                                    className="btn btn-tab-secondary"
                                    onClick={() => buttonScrollHandler(document.getElementById('llv-investment'))}
                                  >
                                    Investment
                                  </button>
                                </li>
                                <li className="nav-item">
                                  <button 
                                    className="btn btn-tab-secondary"
                                    onClick={() => buttonScrollHandler(document.getElementById('llx-incubations'))}
                                  >
                                    Incubations
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row col-md-12 dashed-border-bottom base-program-group m-0">
                          <div className="col-xs-6 col-sm-6 col-md-6 image-place text-center">
                            {item.innerBlocks.map((element, i) => {
                              if (element.name === "core/image" && element.attributes.className === "llv-base") {
                                return(
                                <Button 
                                  className="btn btn-llv-base" 
                                  onClick={() => buttonScrollHandler(document.getElementById('llv-title'))}
                                >
                                  <img 
                                    src={element.attributes.url}
                                    alt={element.attributes.title}
                                    width={resizeScreen ? "100" : "300"}
                                    height="auto"
                                  />
                                </Button>
                                )
                              }
                            })}
                          </div>
                          <div className="col-xs-6 col-sm-6 col-md-6 image-place">
                            {item.innerBlocks.map((element, i) => {
                              if (element.name === "core/image" &&  element.attributes.className === "llx-base") {
                                return(
                                  <Button 
                                    className="btn btn-llx-base" 
                                    onClick={() => buttonScrollHandler(document.getElementById('llx-title'))}
                                  >
                                    <img 
                                      src={element.attributes.url}
                                      alt={element.attributes.title}
                                      width={resizeScreen ? "120" : "200"}
                                      height="auto"
                                    />
                                  </Button>
                                )
                              }
                            })}
                          </div>
                        </div>
                      </>
                    )
                  } else if (item?.attributes?.className === "llv-title") {
                    return(
                      <div className="row col-md-12 dashed-border-bottom llv-title" id="llv-title">
                        <div className="col-md-6 image-place">
                          {item.innerBlocks.map((element, i) => {
                            if (element.name === "core/image") {
                              return(
                                <a href={`#`}>
                                  <img 
                                    src={element.attributes.url}
                                    alt={element.attributes.title}
                                    width={
                                      resizeScreen 
                                      ? element.attributes.className === "llv-logo-title" ? "100" : "500"
                                      : element.attributes.className === "llv-logo-title" ? "150" : "500"
                                    }
                                    height="auto"
                                    className={`${element.attributes.className} mb-3`}
                                  />
                                </a>
                              )
                            }
                          })}
                        </div>
                        <div className="col-md-6 text-place">
                          <div className="col-md-12">
                            <HeadingBasic 
                              h2={
                                item.innerBlocks.find(x => x.name === "core/heading").attributes.content
                              } 
                              className="mw-100 grey-header"
                            />
                          </div>
                          <div className="col-md-12">
                            {item.innerBlocks.map(element => {
                              if (element.name === "core/paragraph") {
                                return(
                                  <ReactMarkdown
                                    children={element.originalContent}
                                    escapeHtml={false}
                                  />
                                )
                              }
                            })} 
                          </div>
                        </div>
                      </div>
                    )
                  } else if (item?.attributes?.className === "llv-wwa") {
                    return(
                      <div className="row col-md-12 dashed-border-bottom llv-wwa">
                        <div className="col-md-12">
                          <HeadingBasic 
                            h2={
                              item.innerBlocks.find(x => x.name === "core/heading").attributes.content
                            } 
                            className="mw-100 grey-header"
                          />
                        </div>
                        <div className="col-md-12">
                          {item.innerBlocks.map(element => {
                            if (element.name === "core/paragraph") {
                              return(
                                <ReactMarkdown
                                  children={element.originalContent}
                                  escapeHtml={false}
                                />
                              )
                            }
                          })} 
                        </div>
                      </div>
                    )                    
                  } else if (item?.attributes?.className === "llv-ourapproach") {
                    return(
                      <div className="row col-md-12 dashed-border-bottom llv-ourapproach">
                        <div className="col-md-6 image-place">
                          {item.innerBlocks.map((element, i) => {
                            if (element.name === "core/image") {
                              return(
                                <a href={`#`}>
                                  <img 
                                    src={element.attributes.url}
                                    alt={element.attributes.title}
                                    width="500"
                                    height="auto"
                                  />
                                </a>
                              )
                            }
                          })}
                        </div>
                        <div className="col-md-6 text-place">
                          <div className="col-md-12">
                            <HeadingBasic 
                              h2={
                                item.innerBlocks.find(x => x.name === "core/heading").attributes.content
                              } 
                              className="mw-100 grey-header line-header"
                            />
                          </div>
                          <div className="col-md-12">
                            {item.innerBlocks.map(element => {
                              if (element.name === "core/list") {
                                return(
                                  <ul>
                                    <ReactMarkdown
                                      source={element.attributes.values}
                                      escapeHtml={false}
                                    />
                                  </ul>
                                )
                              }
                              if (element.name === "core/paragraph") {
                                return(
                                  <ReactMarkdown
                                    children={element.originalContent}
                                    escapeHtml={false}
                                  />
                                )
                              }
                            })} 
                          </div>
                        </div>
                      </div>
                    ) 
                  } else if (item?.attributes?.className === "llv-investment") {
                    return(
                      <div className="row col-md-12 dashed-border-bottom llv-investment" id="llv-investment">
                        <div className="col-md-6 heading-place">
                          <HeadingBasic 
                            h2={
                              item.innerBlocks.find(x => x.name === "core/heading").attributes.content
                            } 
                            className="mw-100 grey-header"
                          />
                        </div>
                        <div className="col-md-6 text-place">
                          <div className="row col-md-12">
                            {item.innerBlocks.map((element, i) => {
                              return(
                                <>
                                  {element.name === "core/heading" && i >= 1 &&(
                                    <div className="col-md-6">
                                      <HeadingBasic 
                                        h3={element.attributes.content} 
                                        className="mw-100 grey-header"
                                      />
                                    </div>
                                  )}
                                  {element.name === "core/paragraph" && (
                                    <div className="col-md-6">
                                      <ReactMarkdown
                                        children={element.originalContent}
                                        escapeHtml={false}
                                      />
                                    </div>
                                  )}
                                </>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    ) 
                  } else if (item?.attributes?.className === "llv-portofolio") {
                    return(
                      <div className="row col-md-12 dashed-border-bottom llv-portofolio" id="llv-portofolio">
                        <div className="col-md-12 heading-place">
                          <HeadingBasic 
                            h2={
                              item.innerBlocks.find(x => x.name === "core/heading").attributes.content
                            }
                            className="mw-100 grey-header"
                          />
                        </div>
                        <div className="col-md-12">
                          {item.innerBlocks.map(element => {
                            if (element.name === "core/paragraph") {
                              return(
                                <ReactMarkdown
                                  children={element.originalContent}
                                  escapeHtml={false}
                                />
                              )
                            }
                          })} 
                        </div>
                        <div className="col-md-12 gallery-place">
                          <div className="col-md-12 p-0">
                          <CardSlider visibleInitial={false} noGutter={true} showInitial={`5`} arrowsSmDown={true} arrowsMdDown={true}>
                            {item.innerBlocks.map(element => 
                              element.name === "acf/acfgallery" &&
                                element.acf.images.map(listItem => {
                                  return(
                                    <Card
                                      key={listItem.title}
                                      variant="overlay"
                                      img={listItem.sourceUrl}
                                      link={listItem.title}
                                      imgHeight="h-ratio-3-2"
                                      imgOverlay="0"
                                      className="card-nav"
                                    />
                                  )
                                })
                            )}
                          </CardSlider>
                          </div>
                        </div>
                      </div>
                    ) 
                  }else if (item?.attributes?.className === "llx-title") {
                    return(
                      <div className="row col-md-12 dashed-border-bottom llx-title" id="llx-title">
                        <div className="col-md-12 image-place text-center">
                          {item.innerBlocks.map((element, i) => {
                            if (element.name === "core/image") {
                              return(
                                <a href={`#`}>
                                  <img 
                                    src={element.attributes.url}
                                    alt={element.attributes.title}
                                    width="500"
                                    height="auto"
                                  />
                                </a>
                              )
                            }
                          })}
                        </div>
                        <div className="col-md-12 heading-place text-center">
                          <HeadingBasic 
                            h2={
                              item.innerBlocks.find(x => x.name === "core/heading").attributes.content
                            }
                            className="mw-100 grey-header"
                          />
                        </div>
                        <div className="col-md-12 text-place text-center">
                          {item.innerBlocks.map(element => {
                            if (element.name === "core/paragraph") {
                              return(
                                <ReactMarkdown
                                  children={element.originalContent}
                                  escapeHtml={false}
                                />
                              )
                            }
                          })} 
                        </div>
                      </div>
                    )
                  }else if (item?.attributes?.className === "llx-detail") {
                    return(
                      <div className="row col-md-12 dashed-border-bottom llx-detail" id="llx-detail">
                        <div className="col-md-12 image-place text-center">
                          {item.innerBlocks.map((element, i) => {
                            if (element.name === "core/image") {
                              return(
                                <a href={`#`}>
                                  <img 
                                    src={element.attributes.url}
                                    alt={element.attributes.title}
                                    width="500"
                                    height="auto"
                                  />
                                </a>
                              )
                            }
                          })}
                        </div>
                        <div className="col-md-12 text-place">
                          {item.innerBlocks.map(element => 
                            element.name === "acf/labelvalues" &&
                              element.acf.list_label_values.map((listItems, y) => {
                                return(
                                  <div key={y} className={`llx-headpa-${y}`}>
                                    <h2>{listItems.label}</h2>
                                    <span>{listItems.value}</span>
                                  </div>
                                )
                              })
                          )} 
                        </div>
                      </div>
                    )
                  }else if (item?.attributes?.className === "llx-into-business") {
                    return(
                      <div className="row col-md-12 dashed-border-bottom llx-into-business">
                        <div className="row col-md-12">
                          <div className="col-md-12 heading-place text-center">
                            <HeadingBasic 
                              h2={
                                item.innerBlocks.find(x => x.name === "core/heading").attributes.content
                              }
                              className="mw-100 grey-header"
                            />
                          </div>
                          <div className="col-md-2 left-text-place">
                            {item.innerBlocks.map(element => {
                              if (element.name === "core/paragraph" && element.attributes.className === "left-text") {
                                return(
                                  <ReactMarkdown
                                    children={element.originalContent}
                                    escapeHtml={false}
                                  />
                                )
                              }
                            })} 
                          </div>
                          <div className="col-md-8 image-place text-center">
                            {item.innerBlocks.map((element, i) => {
                              if (element.name === "core/image") {
                                return(
                                  <a href={`#`}>
                                    <img 
                                      src={element.attributes.url}
                                      alt={element.attributes.title}
                                      width="500"
                                      height="auto"
                                    />
                                  </a>
                                )
                              }
                            })}
                          </div>
                          <div className="col-md-2 right-text-place">
                            {item.innerBlocks.map(element => {
                              if (element.name === "core/paragraph" && element.attributes.className === "right-text") {
                                return(
                                  <ReactMarkdown
                                    children={element.originalContent}
                                    escapeHtml={false}
                                  />
                                )
                              }
                            })} 
                          </div>
                        </div>
                        <div className="col-md-12 text-center">
                          {item.innerBlocks.map(element => {
                            if (element.name === "core/paragraph" && element.attributes.className === "usecase-text") {
                              return(
                                <ReactMarkdown
                                  children={element.originalContent}
                                  escapeHtml={false}
                                />
                              )
                            }
                          })} 
                        </div>
                      </div>
                    )
                  }else if (item?.attributes?.className === "llx-incubations") {
                    return(
                      <div className="row col-md-12 dashed-border-bottom llx-incubations" id="llx-incubations">
                        <div className="col-md-3 heading-place">
                          <HeadingBasic 
                            h2={
                              item.innerBlocks.find(x => x.name === "core/heading").attributes.content
                            } 
                            className="mw-100 grey-header" 
                          />
                        </div>
                        <div className="col-md-9 image-place">
                          <CardSlider visibleInitial={false} noGutter={true} showInitial={`3`} arrowsSmDown={true} arrowsMdDown={true}>
                            {item.innerBlocks.map(element => 
                              element.name === "acf/acfgallery" &&
                                element.acf.images.map(listItem => {
                                  return(
                                    <Card
                                      key={listItem.title}
                                      variant="overlay"
                                      img={listItem.sourceUrl}
                                      link={listItem.title}
                                      imgHeight="h-ratio-3-2"
                                      imgOverlay="0"
                                      className="card-nav"
                                    />
                                  )
                                })
                            )}
                          </CardSlider>
                        </div>
                      </div>
                    )
                  }else if (item?.attributes?.className === "ending-address") {
                    return(
                      <div className="row col-md-12 ending-address">
                        {item.innerBlocks.map((element, i) => {
                          if (element.name === "core/image") {
                            return(
                              <div className="col-xs-6 col-md-4 image-place text-center">
                                <a href={`#`}>
                                  <img 
                                    src={element.attributes.url}
                                    alt={element.attributes.title}
                                    width={
                                      resizeScreen
                                      ? element.attributes.className === "llv-logo" ? "100" : "120"
                                      : element.attributes.className === "llv-logo" ? "300" : "200"
                                    }
                                    height="auto"
                                    className={element.attributes.className}
                                  />
                                </a>
                              </div>
                            )
                          }
                        })}
                        <div className="col-xs-12 col-md-4 text-place">
                          <LlvGoogleMapsEmbed embedUrl={embedMaps} />
                        </div>
                      </div>
                    )
                  }
                default:
                  return(<></>)
              }
            })}
          </div>
        </Container>
      </Section>
    </Layout>
  )
}
export default LivingLabVenturesPage